import type {
  ActionTree,
  MutationTree
} from 'vuex';

import lotDetailInfo from '../queries/lotDetailInfo.graphql';

import {
  Flat as BaseFlat
} from '../types/Flat';

import {
  WindowView
} from '@/types/WindowView';
import { useBuildingsStore } from '@/store/buildings';

interface Flat extends BaseFlat {
  planPng: string;
  planHover: string;
  windowView: WindowView;
}

interface State {
  flat?: Flat;
}

const getDefaultState = (): State => {
  return {
    flat: undefined
  };
};

export const state = (): State => getDefaultState();

// eslint-disable-next-line
export type RootState = any;

export const actions: ActionTree<State, RootState> = {
  reset ({ commit }): void {
    commit('RESET');
  },

  async getFlat ({ commit }, id): Promise<void> {
    try {
      const { data } = await this.$portal.post('/graphql/', {
        query: lotDetailInfo?.loc?.source?.body,
        variables: {
          id
        }
      });

      const flat = data.data?.lotDetailInfo;
      commit('SET_FLAT', flat);

      if (!flat) {
        throw new Error('Нет квартиры');
      }
    } catch (error) {
      console.error('🚀 ~ file: flat.ts ~ getFlat ~ e', error);
      this.$sentry.captureException(error);
      throw (error);
    }
  },

  // deprecated
  getFlatPriceWithDiscount ({ state }, price) {
    if (!state.flat) {
      return 0;
    }

    const buildingsStore = useBuildingsStore();

    if (!buildingsStore.buildingsWithFlats?.length) {
      return 0;
    }

    const { building } = state.flat;

    const buildingDiscount = buildingsStore.buildingsWithFlats
      .find(({ globalId }) => globalId === building.id)?.discount;

    return buildingDiscount ? price * buildingDiscount / 100 : price;
  }
};

export const mutations: MutationTree<State> = {
  RESET (
    state
  ): void {
    Object.assign(state, getDefaultState());
  },

  SET_FLAT (
    state: State,
    payload: Flat
  ): void {
    state.flat = payload;
  }
};
