import { useApiClient } from '@/plugins/axios';

export interface UserBookingRequest {
  id: number;
  'amocrm_stage': string;
  'amocrm_substage': string;
  project: {
    name: string;
    slug: string;
    city: string;
  };
}

/**
 *  Получение сделок клиента
 * @param userId
 */
export async function getUserBookings (userId: number): Promise<Array<UserBookingRequest>> {
  const url = '/api/dsl/booking_by_user';

  const { data } = await useApiClient()
    .get<Array<UserBookingRequest>>(url, {
      params: {
        user_id: userId
      }
    });

  return data;
}
