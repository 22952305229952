import { defineStore } from 'pinia';
import {
  ref,
  useContext
} from '@nuxtjs/composition-api';
import {
  AcceptRewardRequest,
  Advantage,
  Agent,
  Condition,
  HistoryDetails,
  OperationDetail,
  RewardRequest,
  RewardResponse,
  StatusLoyalty
} from '@/types/Loyalty';

interface AgentResponse {
  agent: Agent;
  status: StatusLoyalty;
}

interface PointsHistoryResponse {
  month: Date;
  result: Array<HistoryDetails | OperationDetail>;
}

interface OfferResponse {
  title: string;
  text: string;
}

const EMPTY_AGENT_INFO: AgentResponse = {
  agent: {
    fullName: '-',
    email: '-',
    phone: '-',
    pointsAwarded: '-',
    pointsDeducted: '-',
    point: '-',
    acceptOffer: null,
    privileges: []
  },
  status: {
    label: '-',
    icon: null, // иконка
    background: {}, // подложка
    nextAmount: '-', // до следующего статуса
    date: '-' // дата получения статуса
  }
};

export const useLoyaltyStore = defineStore('loyalty', () => {
  const {
    $auth,
    $sentry,
    $loyalty
  } = useContext();

  const agentInfo = ref<AgentResponse>();
  const offer = ref<OfferResponse>();
  const advantages = ref<Array<Advantage>>();
  const pointsHistory = ref<Array<PointsHistoryResponse>>();
  const conditions = ref<Condition>();
  const rewardCards = ref<Array<RewardResponse>>();

  async function getAgentInfo (): Promise<void> {
    try {
      const url = '/v1/agent/info';
      const token = $auth.strategy.token.get();
      const {
        data
      } = await $loyalty.get<AgentResponse>(url, {
        headers: {
          Authorization: token
        }
      });
      agentInfo.value = data;
      // eslint-disable-next-line
      } catch (error: any) {
      if (error.response.status === 400) {
        agentInfo.value = EMPTY_AGENT_INFO;
      } else {
        console.log('🚀 ~ file: loyalty.ts ~ getAgentInfo ~ error', error);
        $sentry.captureException(error);
      }
    }
  }

  async function getOffer (): Promise<void> {
    try {
      const url = '/v1/content/offer';
      const {
        data
      } = await $loyalty.get<OfferResponse>(url);

      offer.value = data;
    } catch (error) {
      console.error('🚀 ~ file: loyalty.ts ~ getOffer ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function acceptOffer (): Promise<void> {
    try {
      const url = 'v1/agent/accept_offer';
      const token = $auth.strategy.token.get();
      await $loyalty.post(url, {}, {
        headers: {
          Authorization: token
        }
      });
    } catch (error) {
      console.error('🚀 ~ file: loyalty.ts ~ acceptOffer ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getAdvantages (): Promise<void> {
    try {
      const url = '/v1/content/advantages';
      const {
        data
      } = await $loyalty.get<Array<Advantage>>(url);

      advantages.value = data;
    } catch (error) {
      console.error('🚀 ~ file: loyalty.ts ~ getAdvantages ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getOperationHistory (): Promise<void> {
    try {
      const url = 'v1/agent/operation_history';
      const token = $auth.strategy.token.get();
      const { data } = await $loyalty.get<Array<PointsHistoryResponse>>(url, {
        headers: {
          Authorization: token
        }
      });
      pointsHistory.value = data;
    } catch (error) {
      console.error('🚀 ~ file: loyalty.ts ~ getOperationHistory ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getConditions (slug: string): Promise<void> {
    try {
      const url = '/v1/content/conditions';
      const token = $auth.strategy.token.get();
      const {
        data
      } = await $loyalty.get<Condition>(url, {
        headers: {
          Authorization: token
        },
        params: {
          city: slug
        }
      });

      conditions.value = {
        ...conditions.value,
        [slug]: data
      };
    } catch (error) {
      console.error('🚀 ~ file: loyalty.ts ~ getConditions ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function getRewardCard (payload: RewardRequest): Promise<void> {
    rewardCards.value = undefined; // FIXME отрефакторить strana_lk-4294
    try {
      const url = '/v1/agent/reward_status_card';
      const token = $auth.strategy.token.get();
      const { data } = await $loyalty.get<Array<RewardResponse>>(url, {
        headers: {
          Authorization: token
        },
        params: {
          booking_id: payload.id,
          booking_status: payload.status,
          property_type_slug: payload.propertyTypeSlug,
          project_id: payload.projectId
        }
      });
      rewardCards.value = data;
    } catch (error) {
      console.error('🚀 ~ file: loyalty.ts ~ getRewardCard ~ error', error);
      $sentry.captureException(error);
    }
  }


  async function acceptReward (
    payload: AcceptRewardRequest
  ): Promise<void> {
    const url = 'v1/agent/accept_reward';
    const token = $auth.strategy.token.get();

    await $loyalty.post(url, payload, {
      headers: {
        Authorization: token
      }
    });
  }

  async function cancelReward (payload: RewardRequest): Promise<void> {
    const url = 'v1/agent/cancel_reward';
    const token = $auth.strategy.token.get();

    const body = {
      booking_id: payload.id,
      booking_status: payload.status
    };

    await $loyalty.post(url, body, {
      headers: {
        Authorization: token
      }
    });
  }

  function resetStoreData (): void {
    agentInfo.value = undefined;
    offer.value = undefined;
    advantages.value = [];
    pointsHistory.value = [];
    conditions.value = {};
    rewardCards.value = [];
  }

  return {
    agentInfo,
    offer,
    advantages,
    pointsHistory,
    conditions,
    rewardCards,
    resetStoreData,
    getAgentInfo,
    getOffer,
    acceptOffer,
    getAdvantages,
    getOperationHistory,
    getConditions,
    getRewardCard,
    acceptReward,
    cancelReward
  };
});
